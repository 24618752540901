export const handleZoeRedirect = (zoeUrl, answer) => {
    const url = new URL(zoeUrl || window.location.origin);

    if (answer) {
        url.searchParams.append('first_question', answer);
    }

    url.searchParams.append('origin', window.location.href);
    window.location.href = url.href;
};
